<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_SETTINGS')" type="back" :settings="false" />
    </template>

    <div class="settings-container">
      <div class="container">
        <div class="profile-info">
          <div class="image">
            <img :src="$h.getImage(profileInfo.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy" />
            <f7-button fill small @click="selectImage">
              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
            </f7-button>
          </div>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_ACCOUNT') }}</h1>
        </div>
        <f7-list>
          <f7-list-item :title="$t.getTranslation('LBL_EDIT_PROFILE')" link="/profile/settings/account/"></f7-list-item>
          <f7-list-item v-if="profileInfo?.IsMerge == 0" :title="$t.getTranslation('LBL_MERGE_ACCOUNT')" link="/profile/settings/merge/"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_CHANGE_PASSWORD')" link="/profile/settings/password/"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_WINE_PREFERENCE')" link="/profile/settings/wine/"></f7-list-item>
          <!-- <f7-list-item :title="$t.getTranslation('LBL_MEMBERSHIP_STATUS')" link="/profile/membership/"></f7-list-item> -->
          <!-- <f7-list-item :title="$t.getTranslation('LBL_SHIPPING_INFORMATION')" link="/profile/settings/shipping/"></f7-list-item> -->
          <!-- <f7-list-item :title="$t.getTranslation('LBL_PAYMENT_METHODS')" link="/profile/settings/payment/"></f7-list-item> -->
          <f7-list-item :title="$t.getTranslation('LBL_LANGUAGE')" smart-select :smart-select-params="smartSelectParams" class="languageSelector">
            <select name="languageList">
              <option v-for="language in languageList" :key="'lg_' + language.Code" :value="language.Code" :selected="currentLanguageCode == language.Code">{{ language.Name }}</option>
            </select>
          </f7-list-item>
          <f7-list-item v-if="$configs.couponEnabled && profileInfo && profileInfo.ReferralCode" :title="$t.getTranslation('LBL_SHARE_REFERRAL_LINK')" @click="referralLink($refs)" link="javascript:;"></f7-list-item>
        </f7-list>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_NOTIFICATIONS') }}</h1>
        </div>
        <f7-list>
          <f7-list-item :title="$t.getTranslation('LBL_INFLUENCER_POSTS')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.INFLUENCER_POSTS == 1" @change="toggleSettings($event, 'INFLUENCER_POSTS')"></f7-toggle>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_NEW_PRODUCTS')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.NEW_PRODUCTS == 1" @change="toggleSettings($event, 'NEW_PRODUCTS')"></f7-toggle>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_ORDER_STATUS')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.ORDER_STATUS == 1" @change="toggleSettings($event, 'ORDER_STATUS')"></f7-toggle>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_PROMOTIONS')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.PROMOTIONS == 1" @change="toggleSettings($event, 'PROMOTIONS')"></f7-toggle>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_EVENTS')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.EVENTS == 1" @change="toggleSettings($event, 'EVENTS')"></f7-toggle>
            </template>
          </f7-list-item>
        </f7-list>

        <div class="title">
          <h1>{{ $t.getTranslation('LBL_USER_GUIDELINES') }}</h1>
        </div>

        <f7-list>
          <f7-list-item :title="$t.getTranslation('LBL_TERMS_AND_CONDITIONS')" :link="$configs?.externalPageLinks?.termsAndConditions" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_PRIVACY_POLICY')" :link="$configs?.externalPageLinks?.privacyPolicy" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_ORDER_AND_SHIPPING')" :link="$configs?.externalPageLinks?.orderAndShipping" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_RETURN_POLICY')" :link="$configs?.externalPageLinks?.returnPolicy" external target="_blank"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_DEVELOPMENT_MODE')" link="/order/test"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_NEWSLETTER_SUBSCRIPTION')">
            <template #after>
              <f7-toggle :checked="profileInfo?.UserSetting?.IsNewsletter == 1" @change="toggleNewsLetter($event, 'IsNewsletter')"></f7-toggle>
            </template>
          </f7-list-item>
        </f7-list>

        <f7-button large fill class="btn-logout" @click="logout">{{ $t.getTranslation('LBL_SIGN_OUT') }}</f7-button>
      </div>
    </div>

    <ImageUploadPopUpComponent
      :description="$t.getTranslation('LBL_SELECT_IMAGE_TO_USE')"
      :open="openPopup"
      :image-size-limit="5"
      :stencil="{ aspectRatio: 1 / 1 }"
      :stencil-props="{ aspectRatio: 1 }"
      @closed="closePopup"
      @cropped="onImageSelected"
    />

    <f7-sheet class="referral-share-sheet" swipe-to-close backdrop>
      <f7-page-content>
        <div class="share-container">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_SHARE') }}</h1>
          </div>
          <div style="display: flex; align-items: flex-start">
            <img v-if="!$h.isMobile() && shareLink" :src="$h.getQRCode(shareLink)" style="width: 100px" loading="lazy" />
            <div class="share-link" @click="copyLink" style="flex: 1; margin-top: 0px">
              <p style="max-width: 80%">{{ profileInfo?.ReferralCode }}</p>
              <span class="copy">{{ $t.getTranslation('LBL_COPY') }}</span>
            </div>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, defineAsyncComponent } from 'vue'

import { post, get } from '@/utils/axios'
import { logout } from '@/logics/auth.js'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers'
import { f7 } from 'framework7-vue'
import { configs } from '@/utils/configs.js'

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import ImageUploadPopUpComponent from "@/components/ImageUploadPopUpComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProfileNavigationComponent.vue'))
const ImageUploadPopUpComponent = defineAsyncComponent(() => import(/* webpackChunkName: "image-upload-popup" */ /* webpackMode: "lazy" */ '@/components/ImageUploadPopUpComponent.vue'))

export default defineComponent({
  name: 'SettingsPage',
  components: {
    ProfileNavigationComponent,
    ImageUploadPopUpComponent
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject('$translation')

    const store = useStore()

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])

    store.dispatch('config/fetchData', { params: { languageList: 1 } })
    const languageList = computed(() => store.getters['config/getData']?.languageList)

    const openPopup = ref(false)
    const profileInfo = ref(false)
    const shareLink = ref(null)

    const smartSelectParams = {
      openIn: 'popover',
      routableModals: false,
      closeOnSelect: true,
      on: {
        closed: async (smartSelect) => {
          let languageCode = smartSelect?.$selectEl?.val()
          store.dispatch('translation/setLanguage', { languageCode, postUpdate: true })
          store.dispatch('translation/fetchData')
          store.dispatch('config/fetchData', { refetch: true })
        }
      }
    }

    onMounted(() => {
      getProfileInfo()
    })

    const closePopup = () => {
      openPopup.value = false
    }

    const selectImage = (type) => {
      openPopup.value = true
    }

    const onImageSelected = async (data) => {
      const formData = new FormData()
      formData.append('ProfileImage', data?.blob)
      await post('/user/profile/image/fromformdata', formData)
      await getProfileInfo()
    }

    const getProfileInfo = async () => {
      profileInfo.value = await get('/user/info')
      //render sharelink
      shareLink.value = configs.baseURL.replace('/api', '/r/' + profileInfo.value.ReferralCode)
    }

    const toggleSettings = async (event, type) => {
      await post('/user/update/setting', {
        Type: type,
        Value: event?.target?.checked ? 1 : 0
      })
    }

    const toggleNewsLetter = async (event) => {
      await post('/user/newsletter', {
        IsNewsletter: event?.target?.checked ? 1 : 0
      })
    }

    const referralLink = ($refs) => {
      //if inside wechat browser render a new page
      if (helpers.isInsideWeixin()) {
        return props.f7router.navigate({ name: 'referralView', params: { code: profileInfo.value.ReferralCode } })
      }

      const isSecured = location.protocol.indexOf('https') > -1 ? true : false
      if (navigator.share && isSecured && helpers.isMobile()) {
        //native share dialog
        navigator.share({
          title: $t.getTranslation('TTL_REFERRAL_LINK_SHARE'),
          text: $t.getTranslation('DESC_REFERRAL_LINK_SHARE'),
          url: shareLink.value
        })
      } else {
        f7.sheet.get('.referral-share-sheet').open()
      }
    }

    const copyLink = async () => {
      helpers.copyToClipBoard(shareLink.value)
      helpers.createNotification({
        type: 'info',
        title: $t.getTranslation('LBL_INFO'),
        message: $t.getTranslation('LBL_INFO_SUCCESSFULLY_COPY_SHARE_LINK')
      })
    }

    return {
      shareLink,
      copyLink,
      referralLink,
      currentLanguageCode,
      languageList,
      smartSelectParams,
      profileInfo,
      toggleSettings,
      logout,
      openPopup,
      closePopup,
      selectImage,
      onImageSelected,
      toggleNewsLetter
    }
  }
})
</script>

<style>
.btn-logout {
  width: calc(100% - 40px);
  margin: 40px auto 0px;
}
.smart-select-popover {
  --f7-list-item-title-font-weight: 400;
  --f7-list-item-title-font-size: 14px;
}
</style>
